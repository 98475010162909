@font-face {
	font-family: 'Lora Regular';
	src: url('../fonts/Lora-Regular.eot');
	src: url('../fonts/Lora-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lora-Regular.woff2') format('woff2'),
		url('../fonts/Lora-Regular.woff') format('woff'),
		url('../fonts/Lora-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Lora Bold';
	src: url('../fonts/Lora-Bold.eot');
	src: url('../fonts/Lora-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lora-Bold.woff2') format('woff2'),
		url('../fonts/Lora-Bold.woff') format('woff'),
		url('../fonts/Lora-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

